import React, { Component } from 'react';
import { navigate } from 'gatsby';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactForm extends Component {
  state = { isValidated: false }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    const { lang } = this.props;
    return (
        <form
            name="contact"
            method="post"
            action={lang === 'pl' ? "/contact/dziekuje/" : "/contact/thanks/"}
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />

          <div className="field">
            <label className="label" htmlFor={'firstName'}>
              {lang === "pl" ? "Imię" : "First name"}
            </label>
            <div className="control">
              <input
                className="input"
                type={'text'}
                name={'firstName'}
                onChange={this.handleChange}
                id={'firstName'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={'lastName'}>
              {lang === "pl" ? "Nazwisko" : "Last name"}
            </label>
            <div className="control">
              <input
                  className="input"
                  type={'text'}
                  name={'lastName'}
                  onChange={this.handleChange}
                  id={'lastName'}
                  required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={'email'}>
              {lang === "pl" ? "Twój Email" : "Email"}
            </label>
            <div className="control">
              <input
                className="input"
                type={'email'}
                name={'email'}
                onChange={this.handleChange}
                id={'email'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={'phone'}>
              {lang === "pl" ? "Telefon" : "Contact number"}
            </label>
            <div className="control">
              <input
                  className="input"
                  type={'text'}
                  name={'phone'}
                  onChange={this.handleChange}
                  id={'phone'}
                  required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={'message'}>
              {lang === "pl" ? "Krótka wiadomość" : "Short message"}
            </label>
            <div className="control">
              <textarea
                className="textarea"
                name={'message'}
                onChange={this.handleChange}
                id={'message'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={'consent'}></label>
            <div className="control">
              <input type="checkbox" name={'consent'} id={'consent'} required={true} />
              {
                (lang === "pl") ?
                  <>
                    <span>&nbsp;&nbsp;Wyrażam zgodę na przetwarzanie moich danych osobowych i otrzymywanie informacji drogą elektroniczną.</span>
                    <p className="is-size-6">Wyrażenie zgody jest dobrowolne, lecz niezbędne do tego, żebyśmy mogli się z Tobą kontaktować i odpowiadać na Twoje pytania. </p>
                  </>
                  :
                  <>
                    <span>&nbsp;&nbsp;I accept the following:</span>
                    <p className="is-size-6">Health to Thrive complies with the General Data Protection Regulation (GDPR) in the following ways: to deliver the services that you have requested and to contact you, as necessary in accordance with the services you have requested. </p>
                  </>
              }
            </div>
          </div>
          <div className="field">
            <button className="button is-link" type="submit">
              {lang === "pl" ? "Wyślij" : "Send"}</button>
          </div>
        </form>
    )
  }
}
export default ContactForm;