import React from 'react'

const buttonStyle = {
  margin: '20px',
  backgroundColor: 'transparent',
  color: 'white',
  fontSize: '1.3rem',
  border: '1px solid white',
  borderRadius: '25px',
  padding: '15px 30px'
}

const buttonStyleDark = {
  margin: '20px',
  backgroundColor: 'transparent',
  color: 'black',
  fontSize: '1.3rem',
  border: '1px solid white',
  borderRadius: '25px',
  padding: '15px 30px'
}

const Button = (props) => {
  return (
    <a style={props.dark ? buttonStyleDark : buttonStyle} href={props.target}>{props.children}</a>
  );
}

export default Button;