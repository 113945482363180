import React, { Component } from 'react';
import { Link } from 'gatsby';

const Navbar = class extends Component {

  state = {
    active: false,
    navBarActiveClass: ''
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
            navBarActiveClass: 'is-active',
          })
          : this.setState({
            navBarActiveClass: '',
          })
      }
    )
  }

  render() {
    const { lang, customNav, customLink1, customLink1Target, customLink2, customLink2Target, customLink3, customLink3Target, customLink4, customLink4Target } = this.props
    const { navBarActiveClass, } = this.state
    return (
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main-navigation"
        style={{
          boxShadow: '0 0 10px rgba(0, 0, 0, .1)',
          position: 'fixed',
          width: '100%'
        }}
      >
        <div className="container">
          <div className="navbar-brand">

            <Link to={lang === 'pl' ? '/pl/#' : '#'} className="navbar-item" title="Logo">
              <h3 className="is-size-5">Heal to Thrive</h3>
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              {
                customNav ?
                  <>
                    <Link className="navbar-item" to={`${customLink1Target}`}>{customLink1}</Link>
                    <Link className="navbar-item" to={`${customLink2Target}`}>{customLink2}</Link>
                    <Link className="navbar-item" to={`${customLink3Target}`}>{customLink3}</Link>
                    <Link className="navbar-item" to={`${customLink4Target}`}>{customLink4}</Link>
                  </>
                  :
                  <>
                    {
                      (lang === 'pl') ?
                        <>
                          <Link className="navbar-item" to="/pl/#about">O mnie</Link>
                          <Link className="navbar-item" to="/pl/#how-i-work">O terapii</Link>
                          <Link className="navbar-item" to="/pl/#contact">Kontakt</Link>
                          {/* <Link className="navbar-item" to="/pl/events/mapa-marzen">Warsztaty</Link> */}
                          <Link className="navbar-item" to="/pl/blog">Blog</Link>
                        </>
                        :
                        <>
                          <Link className="navbar-item" to="#about-emdr">About EMDR</Link>
                          <Link className="navbar-item" to="#training-and-experience">Training & Experience</Link>
                          <Link className="navbar-item" to="#how-i-work">How I work</Link>
                          <Link className="navbar-item" to="#contact">Contact Information</Link>
                        </>
                    }
                  </>
              }

            </div>
          </div>
        </div>
      </nav >
    )
  }
}

export default Navbar;
