import React from 'react';
import Button from '../components/Button';


const Hero = ({ image, heading, subheading, buttonText, buttonTarget, fullHeight, scroll }) => (
  <div
    className="full-width-image"
    style={{
      backgroundImage: `url(${image})`,
      backgroundPosition: `center center`,
      backgroundAttachment: scroll ? `scroll` : `fixed`,
      height: fullHeight ? '100vh' : '65vh',
      marginTop: '3.25rem'
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '1',
        alignItems: 'center'
      }}
    >
      {heading &&
        <h1
          className="is-size-3-mobile is-size-2-tablet is-size-1-widescreen has-text-centered"
          style={{
            textShadow: '0 0 10px rgba(0,0,0,.5)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
            borderBottom: '1px solid white',
            marginBottom: '1rem',
            paddingBottom: '1rem'
          }}
        >
          {heading}
        </h1>}
      <p
        className="is-size-5-mobile is-size-5-tablet is-size-2-widescreen has-text-center"
        style={{
          textShadow: '0 0 5px rgba(0,0,0,.5)',
          color: 'white',
          lineHeight: '1',
          padding: '0.25em',
          fontWeight: '400',

        }}
      >
        {subheading}
      </p>
      {buttonText &&
        <Button target={buttonTarget}>{buttonText}</Button>
      }

    </div>
  </div>

);

export default Hero;