import React from 'react';
import styles from './PageSection.module.css';

const PageSection = ({ id, heading, backgroundColor, children }) => (
  <section id={id} className="section" style={{ backgroundColor: backgroundColor ? backgroundColor : 'white' }}>
    <div className="container">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="content">
            <h2 className={`is-size-3 has-text-centered ${styles.h2}`}>{heading}</h2>
            {children}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default PageSection;